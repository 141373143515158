import { useEffect } from 'react'
import socketIOClient from 'socket.io-client'
import { baseURL } from 'store/constant'
import { dispatch } from 'store'
import uuidv4 from 'utils/uuidv4'

let socket
export default () => {
    const init = async () => {
        socket = socketIOClient.connect(baseURL)

        socket.on('connect', () => {
            dispatch({ type: 'SET_SOCKET_ID', payload: socket.id })
            const randomInt = Math.random().toString(36).substring(7)
            const roomId = `${randomInt}-${socket.id}`
            dispatch({ type: 'SET_ROOM_ID', payload: roomId })
            socket.on(roomId, (data) => {
                console.log('socket data', data)
                const uniqueId = uuidv4()
                dispatch({ type: 'SET_SOCKET_DATA', payload: { ...data, ___idd: uniqueId } })
            })
        })
    }
    useEffect(() => {
        init()
        return () => {
            if (socket) {
                socket.disconnect()
                dispatch({ type: 'CLEANUP_SOCKET' })
            }
        }
    }, [])

    return {
        socket
    }
}
