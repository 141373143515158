import client from './client'
const saveLocalConnection = (payload) => client.post(`/app-connections`, payload)
const getLocalConnections = () => client.get(`/app-connections`)
export default {
    saveLocalConnection,
    getLocalConnections,
    deleteConnection: (id) => client.delete(`/app-connections/${id}`),
    updateConnection: (id, payload) => client.put(`/app-connections/${id}`, payload),
    addConnection: (payload) => client.post(`/app-connections`, payload)
}
