/* eslint-disable no-useless-escape */
/* eslint-disable no-useless-backreference */
import { ArrowRightCircleIcon, TrashIcon } from '@heroicons/react/20/solid'
import TagIcon from '@mui/icons-material/TagOutlined'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonthOutlined'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmailOutlined'
import LinkIcon from '@mui/icons-material/LinkOutlined'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined'
import FunctionsIcon from '@mui/icons-material/Functions'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import ChecklistIcon from '@mui/icons-material/Checklist'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import moment from 'moment'
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined'
import TextFieldsIcon from '@mui/icons-material/TextFields'
const headingActions = {
    action: [
        // {
        //     title: 'Pin',
        //     icon: ArchiveBoxIcon,
        //     id: 'pin'
        // },
        {
            title: 'Delete',
            icon: TrashIcon,
            id: 'delete'
        }
    ],

    sort: [
        // {
        //     title: 'Duplicate',
        //     icon: DocumentDuplicateIcon,
        //     id: 'duplicate'
        // },
        {
            title: 'Hide',
            icon: ArrowRightCircleIcon,
            id: 'hide'
        },
        {
            title: 'Sort A-Z',
            icon: ArrowRightCircleIcon,
            id: 'sort-az'
        },
        {
            title: 'Sort Z-A',
            icon: ArrowRightCircleIcon,
            id: 'sort-za'
        }
        // {
        //     title: 'Filter',
        //     icon: UserPlusIcon,
        //     id: 'filter'
        // }
    ],
    crud: [
        // {
        //     title: 'Rename Column',
        //     icon: PencilSquareIcon,
        //     id: 'rename'
        // },
        // {
        //     title: 'Edit Column',
        //     icon: PencilSquareIcon,
        //     id: 'edit'
        // },
        // {
        //     title: 'type',
        //     icon: DocumentDuplicateIcon,
        //     id: 'type',
        //     children: true,
        //     position: 'typechange'
        // },
        {
            title: 'Insert left',
            icon: SubdirectoryArrowLeftIcon,
            id: 'add-left',
            children: true,
            position: 'left'
        },
        {
            title: 'Insert right',
            icon: SubdirectoryArrowRightIcon,
            id: 'add-right',
            children: true,
            position: 'right'
        }
    ]
}
const icons = {
    text: TextFormatIcon,
    longText: TextFieldsIcon,
    string: TextFormatIcon,
    number: TagIcon,
    date: CalendarMonthIcon,
    email: AlternateEmailIcon,
    imageurl: InsertPhotoOutlinedIcon,
    url: LinkIcon,
    select: ExpandCircleDownOutlinedIcon,
    multiSelect: ChecklistIcon,
    checkbox: CheckBoxOutlinedIcon,
    AI: PsychologyOutlinedIcon,
    Formula: FunctionsIcon,
    json: DataObjectOutlinedIcon
}
const headingTypes = [
    // { title: 'AI', icon: icons.AI, id: 'Recipe' },
    { title: 'Text', icon: icons.text, id: 'text' },
    { title: 'Long Text', icon: icons.longText, id: 'longText' },
    { title: 'Number', icon: icons.number, id: 'number' },
    { title: 'Date', icon: icons.date, id: 'date' },
    { title: 'Email', icon: icons.email, id: 'email' },
    { title: 'Image', icon: icons.imageurl, id: 'imageurl' },
    { title: 'Url', icon: icons.url, id: 'url' },
    { title: 'Single select', icon: icons.select, id: 'select' },
    { title: 'Multiple select', icon: icons.multiSelect, id: 'multiSelect' },
    { title: 'Checkbox', icon: icons.checkbox, id: 'checkbox' },
    { title: 'Formula', icon: icons.Formula, id: 'Formula' },
    { title: 'JSON', icon: icons.json, id: 'json' }
]
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
// const get
export const titleCase = (str) => {
    return str.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase()
    })
}
export const checkReference = (row, columns, inputString, returnRefObj = false) => {
    const labels = columns.map((cell) => cell.label.trim().toLowerCase()).sort((a, b) => b.length - a.length)
    const regex = generateLabelRegex(labels)
    let matches = [...inputString.toLowerCase().matchAll(regex)].map((match) => match[1])
    matches = [...new Set(matches)]
    if (!matches) {
        return ''
    }
    const referenceObject = matches.reduce((acc, label) => {
        const column = columns.find((col) => col.label.toString().toLowerCase() === label.toString().toLowerCase())
        if (column && row[column._id]) {
            acc[label] = row[column._id]
        }
        return acc
    }, {})
    if (returnRefObj) return referenceObject
    // check every key in label is in the referenceObject and has a value
    const allKeys = matches.filter((label) => !referenceObject[label.trim().toLowerCase()])
    return allKeys
}
export function generateLabelRegex(labels) {
    labels = labels.map((label) => label.trim().toLowerCase())
    labels.sort((a, b) => b.length - a.length)
    const pattern = new RegExp(`@(${labels.map((label) => label.replace(/\s+/g, '\\s+')).join('|')})\\b`, 'gi')
    return pattern
}
export function executeFormula(cell, row, cells) {
    try {
        if (cell.isConditional) {
            const conditions = cell.condition
            const columnMapping = checkReference(row, cells, conditions, true)
            const result = checkCondition(conditions, columnMapping)
            if (!result) return ''
        }
        const labels = cells.map((cell) => cell.label.trim().toLowerCase()).sort((a, b) => b.length - a.length)
        const regex = generateLabelRegex(labels)
        let matches = [...cell.prompt.toLowerCase().matchAll(regex)].map((match) => match[1])
        matches = [...new Set(matches)]
        if (!matches) {
            return ''
        }
        const referenceObject = matches.reduce((acc, label) => {
            const column = cells.find((col) => col.label.toString().toLowerCase() === label.toString().toLowerCase())
            if (column && row[column._id]) {
                acc[label] = row[column._id]
            }
            return acc
        }, {})
        const formulaString = cell.prompt
            .replace(regex, (_, key) => {
                key = key.trim().toLowerCase()
                return referenceObject[key] ?? 0
            })
            .replace(/@/g, '')
        const a = eval(formulaString)
        return a
    } catch (e) {
        console.error(e)
        return ''
    }
}
export function checkCondition(condition, columnMapping) {
    // Replace placeholders in the condition with the corresponding values from the row
    let evaluatedCondition = condition.replace(/{{\s*"(.*?)"\s*}}/gi, (_, columnName) => {
        const value = columnMapping[columnName] // Find the mapped column name
        // Properly quote strings for eval
        return typeof value === 'string' ? `"${value}"` : value
    })

    try {
        // Evaluate the condition safely
        return eval(evaluatedCondition)
    } catch (e) {
        console.error('Error evaluating condition:', e)
        return false
    }
}

export function isMarkdown(input) {
    const markdownPattern = /(\n|^)#{1,6}\s|(\*\*|__)(.*?)\1|[*_-]{3,}|!\[.*?\]\(.*?\)|\[(.*?)\]\(.*?\)|^\d+\.\s|\n\s*[*-]\s/
    return markdownPattern.test(input)
}
export { headingActions, headingTypes, classNames, icons }

export const checkForRunAiCellOnRowUpdate = (row, columns) => {
    const aiCells = columns.filter((col) => col.isRecipe)
    if (aiCells.length === 0) return false
    const nonAiCells = columns.filter((col) => !col.isRecipe)
    if (nonAiCells.length === 0) return false
    const nonAiCellIdMap = {}
    nonAiCells.forEach((col) => {
        nonAiCellIdMap[col.label.trim().toLowerCase()] = col._id
    })
    let toRunCells = []
    aiCells.forEach((cell) => {
        const value = row[cell._id]
        if (!value) {
            const hasReferenceValue = checkReference(row, columns, cell.prompt)
            if (hasReferenceValue.length === 0) {
                toRunCells.push(cell._id)
            }
        }
    })
    return toRunCells
}
export const getInitalAndColor = (item, index) => {
    const initial = item.name.charAt(0).toUpperCase() + item.name.charAt(1)
    const colors = ['bg-red-200', 'bg-blue-200', 'bg-green-200', 'bg-yellow-200', 'bg-indigo-200', 'bg-pink-200', 'bg-purple-200']
    const color = colors[index % colors.length]
    return { initial, color }
}
export const DateFormats = [
    { title: moment().format('DD/MM/YYYY').toString(), id: 'DD/MM/YYYY' },
    { title: moment().format('DD-MM-YYYY').toString(), id: 'DD-MM-YYYY' },
    { title: moment().format('MM/DD/YYYY').toString(), id: 'MM/DD YYYY' },
    { title: moment().format('MM-DD-YYYY').toString(), id: 'MM-DD-YYYY' },
    { title: moment().format('DD MMM, YYYY').toString(), id: 'DD MMM, YYYY' }
]
export const abbreviate = (number) => {
    // like 10, 100, 1K, 10K, 100K, 1M, 10M, 100M, 1B, 10B, 100B, 1T
    if (number < 1000) return number
    const tier = (Math.log10(number) / 3) | 0
    if (tier === 0) return number
    const suffixes = ['K', 'M', 'B', 'T']
    const suffix = suffixes[tier - 1]
    const scale = Math.pow(10, tier * 3)
    const scaled = number / scale
    return scaled.toFixed(1) + suffix
}
export const abbreviateNumber = (number, suffix, prefix) => {
    const a = abbreviate(number)
    return `${prefix ?? ''} ${a} ${suffix ?? ''}`
}
export const getFormattedDate = (date, format) => {
    format = format && format.id ? format.id : format ? format : 'DD/MM/YYYY'
    if (!date) return ''
    if (!moment(date).isValid()) return ''
    if (!format) return moment(date).format('DD/MM/YYYY')
    if (moment(date).format(format) === 'Invalid date') return moment(date).format('DD/MM/YYYY')
    return moment(date).format(format)

    // return moment(date).format(format)
}

// check for tool data errors
export const checkForErrors = (data) => {
    let error = null
    data.forEach((run) => {
        const values = Object.values(run)
        if (values && _.isArray(values)) {
            values.forEach((value) => {
                if (value && typeof value === 'object' && value.error) {
                    error = value.error
                }
            })
        }
    })
    return error
}
export const getOperator = (datatype) => {
    switch (datatype) {
        case 'number':
            return [
                { title: 'Greater than', id: '>' },
                { title: 'Less than', id: '<' },
                { title: 'Equal to', id: '==' },
                { title: 'Not equal to', id: '!=' },
                { title: 'Greater than or equal to', id: '>=' },
                { title: 'Less than or equal to', id: '<=' }
            ]
        case 'date':
            return [
                { title: 'After', id: '>' },
                { title: 'Before', id: '<' },
                { title: 'On', id: '==' },
                { title: 'Not on', id: '!=' },
                { title: 'After or on', id: '>=' },
                { title: 'Before or on', id: '<=' },
                { title: 'Is Empty', id: 'empty' },
                { title: 'Is Not Empty', id: 'notempty' }
            ]
        case 'checkbox':
            return [
                { title: 'Checked', id: 'trye' },
                { title: 'Unchecked', id: 'false' }
            ]
        case 'select':
            return [
                { title: 'Equal to', id: '==' },
                { title: 'Not equal to', id: '!=' },
                { title: 'Is Empty', id: 'empty' },
                { title: 'Is Not Empty', id: 'notempty' }
            ]
        case 'multiSelect':
            return [
                { title: 'Contains', id: 'contains' },
                { title: 'Does not contain', id: 'does not contain' },
                { title: 'Is Empty', id: 'empty' },
                { title: 'Is Not Empty', id: 'notempty' }
            ]
        default:
            return [
                { title: 'Contains', id: 'contains' },
                { title: 'Does not contain', id: 'does not contain' },
                { title: 'Equal to', id: '==' },
                { title: 'Not equal to', id: '!=' },
                { title: 'Is Empty', id: 'empty' },
                { title: 'Is Not Empty', id: 'notempty' }
            ]
    }
}
// one filter is an object with column, operator and value
// { column: { _id: '123', label: 'Name', datatype: 'text }, operator: {title: 'Is Empty', id : ''}, value: 'John' }
// { column: { _id: '123', label: 'Name' , datatype: 'text}, operator: {title: 'Contains', id : 'contains'}, value: 'John' }
// { column: { _id: '123', label: 'Name', datatype: 'text }, operator: {title: 'Contains', id : 'contains'}, value: 'John' }
// { column: { _id: '123', label: 'Created At', datatype: 'date }, operator: {title: 'After or on', id : '>='}, value: '13-12-2003' }
// { column: { _id: '123', label: 'Empyoees count', datatype: 'number }, operator: {title: 'Greater than', id : '>'}, value: 40 }
export const filterRows = (rows, filters, toast, rowFilter) => {
    try {
        const validFilters = filters.filter((filter) => {
            if (filter.column && filter.operator) {
                if (filter.operator.id === 'empty' || filter.operator.id === 'notempty') {
                    return true
                } else {
                    return filter.value !== undefined && filter.value !== null && filter.value !== ''
                }
            } else {
                return false
            }
        })
        const filteredRows = rows.filter((row) => {
            let mainOperator = 'And'
            const anyFilter = validFilters.length > 0
            if (!anyFilter) return true
            const values = validFilters.map((filter) => {
                let include = true
                // check column datatype then conver string value to date object if datatype is date or number if datatype is number or boolean if datatype is checkbox
                const column = filter.column
                const operator = filter.operator
                if (operator && operator.id) {
                    operator.id = operator.id.toString().toLowerCase().trim()
                }

                let value = filter.value
                if (value && typeof value === 'string') {
                    value = value.trim().toLowerCase()
                }

                const andOr = filter.condition
                if (andOr) {
                    mainOperator = andOr.toString().toLowerCase().trim()
                }
                let rowValue = row[column._id]
                if (rowValue && typeof rowValue === 'string') {
                    rowValue = rowValue.trim().toLowerCase()
                }
                if (operator.id === 'empty') {
                    include = include && !rowValue
                } else if (rowValue === undefined || rowValue === null) {
                    include = false
                }

                if (column.datatype === 'date') {
                    rowValue = new Date(rowValue)
                    value = new Date(value)
                }
                if (column.datatype === 'number') {
                    rowValue = parseFloat(rowValue)
                    value = parseFloat(value)
                }
                if (column.datatype === 'checkbox') {
                    rowValue = rowValue === 'true'
                    value = value === 'true'
                }
                if (column.datatype === 'select' || column.datatype === 'multiSelect') {
                    rowValue = rowValue.toString()
                    value = value.toString().toLowerCase()
                }
                if (operator.id === '==') {
                    include = include && rowValue === value
                }
                if (operator.id === '!=') {
                    include = include && rowValue !== value
                }
                if (operator.id === '>') {
                    include = include && rowValue > value
                }
                if (operator.id === '<') {
                    include = include && rowValue < value
                }
                if (operator.id === '>=') {
                    include = include && rowValue >= value
                }
                if (operator.id === '<=') {
                    include = include && rowValue <= value
                }
                if (operator.id === 'contains') {
                    include = include && rowValue.toString().toLowerCase().includes(value.toString().toLowerCase())
                }
                if (operator.id === 'does not contain') {
                    include = include && !rowValue.toString().toLowerCase().includes(value.toString().toLowerCase())
                }
                if (operator.id === 'checked') {
                    include = include && rowValue
                }
                if (operator.id === 'unchecked') {
                    include = include && !rowValue
                }
                if (operator.id === '') {
                    include = include && !rowValue
                }
                if (operator.id === 'empty') {
                    include = include && !rowValue
                }
                if (operator.id === 'notempty') {
                    include = include && rowValue
                }
                return include
            })
            if (mainOperator === 'and') {
                return values.every((v) => v)
            } else {
                return values.some((v) => v)
            }
        })
        if (rowFilter.endingRow > rowFilter.startingRow && rowFilter.execute) {
            return filteredRows.slice(rowFilter.startingRow, rowFilter.endingRow)
        }
        return filteredRows
    } catch (e) {
        console.error(e)
        toast.error('Error in filtering')
        return rows
    }
}
export const getHighlightedCells = (selectedGridIds, newGridId, rows) => {
    const lastGridId = selectedGridIds[selectedGridIds.length - 1]
    if (lastGridId === newGridId) return selectedGridIds
    if (lastGridId === undefined) return [newGridId]
    const [lastRowId, lastCellId] = lastGridId.split('_')
    const [newRowId, newCellId] = newGridId.split('_')
    if (lastCellId === newCellId) {
        const lastFindIndex = rows.findIndex((r) => r._id === lastRowId)
        const newFindIndex = rows.findIndex((r) => r._id === newRowId)
        let startIndex = lastFindIndex < newFindIndex ? lastFindIndex : newFindIndex
        let endIndex = lastFindIndex > newFindIndex ? lastFindIndex : newFindIndex
        const newIds = []
        rows.map((r, i) => {
            //check i between lastFindIndex and newFindIndex
            if (i >= startIndex && i <= endIndex) {
                newIds.push(`${r._id}_${newCellId}`)
            }
        })
        selectedGridIds = [...selectedGridIds, ...newIds]
        selectedGridIds = [...new Set(selectedGridIds)]
    } else {
        selectedGridIds = [newGridId]
    }
    return selectedGridIds
}
export const checkDuplicateRows = (rows, columns, labels) => {
    const uniqueRows = []
    const uniqueRowIds = []
    const duplicateRowIds = []
    const gridIds = []
    const finalColumns = columns.filter((col) => labels.includes(col.label))
    if (labels && labels.length > 0 && finalColumns.length > 0) {
        finalColumns.map((col) => {
            const values = []
            rows.forEach((row) => {
                let value = row[col._id]
                if (value === undefined || value === null) {
                    value = ''
                }
                const str = value.toString().trim().toLowerCase()
                if (!values.includes(str)) {
                    values.push(str)
                } else {
                    gridIds.push({ rowId: row._id, columnId: col._id })
                }
            })
        })
        return gridIds
    }

    rows.forEach((row) => {
        const rowValues = (labels && labels.length > 0 ? finalColumns : columns)
            .map((col) => row[col._id])
            .filter((val) => val !== undefined && val !== null && val !== '')
            .map((val) => val.toString().trim().toLowerCase())
        const rowString = rowValues.join('')
        if (!uniqueRowIds.includes(rowString)) {
            uniqueRows.push(row)
            uniqueRowIds.push(rowString)
        } else {
            duplicateRowIds.push(row._id)
        }
    })
    return duplicateRowIds
}
export function humanReadableToFloat(value) {
    // Remove any non-numeric characters except decimal points, commas, or suffixes like 'k', 'M', 'B'
    value = value.replace(/[^0-9.,kmbBKM]/g, '').toLowerCase()

    // Remove commas from numbers
    value = value.replace(/,/g, '')

    let multiplier = 1

    // Check if the string ends with 'k', 'm', or 'b' and adjust the multiplier accordingly
    if (value.endsWith('k')) {
        multiplier = 1e3 // Thousand
        value = value.replace('k', '')
    } else if (value.endsWith('m')) {
        multiplier = 1e6 // Million
        value = value.replace('m', '')
    } else if (value.endsWith('b')) {
        multiplier = 1e9 // Billion
        value = value.replace('b', '')
    }

    // Convert the cleaned-up value to a float and apply the multiplier
    return parseFloat(value) * multiplier
}
export const inputArgs = ['url', 'query']
export const getSlugFromName = (name) => {
    // return pascal case
    return name.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}
export const findNextCellToFocus = (cell, row, rows, columns, direction) => {
    let nextCell = null
    let rowIndex = rows.findIndex((r) => r === row._id)
    if (direction === 'next') {
        const currentColumnIndex = columns.findIndex((c) => c._id === cell._id)
        if (currentColumnIndex + 1 < columns.length) {
            nextCell = columns[currentColumnIndex + 1]._id
        } else {
            nextCell = columns[0]._id
            if (rowIndex + 1 < rows.length) {
                rowIndex = rowIndex + 1
            } else {
                nextCell = null
            }
        }
    } else if (direction === 'prev') {
        const currentColumnIndex = columns.findIndex((c) => c._id === cell)
        if (currentColumnIndex - 1 >= 0) {
            nextCell = columns[currentColumnIndex - 1]._id
        } else {
            nextCell = columns[columns.length - 1]._id
            if (rowIndex - 1 >= 0) {
                rowIndex = rowIndex - 1
            } else {
                nextCell = null
            }
        }
    }
    if (!nextCell) return null
    const rowId = rows[rowIndex]
    return { rowId, cellId: nextCell, rowIndex }
}
