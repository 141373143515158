import { combineReducers } from 'redux'

// reducer import
import customizationReducer from './reducers/customizationReducer'
import notifierReducer from './reducers/notifierReducer'
import dialogReducer from './reducers/dialogReducer'
import sessionReducer from '../views/auth/store/Reducer'
import storage from 'redux-persist/lib/storage'
import toolsReducer from './reducers/toolReducer'
import datagridReducer from './reducers/datagridReducer'
import toastReducers from '../views/auth/store/Reducer'
import appsReducer from './reducers/appsReducer'
import tableReducer from './reducers/tableReducer'
import socketReducer from './reducers/socketReducer'
// ==============================|| COMBINE REDUCER ||============================== //
const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: []
}
const rootReducer = combineReducers({
    tools: toolsReducer,
    customization: customizationReducer,
    notifier: notifierReducer,
    dialog: dialogReducer,
    session: sessionReducer,
    toast: toastReducers,
    datagrid: datagridReducer,
    apps: appsReducer,
    table: tableReducer,
    socket: socketReducer
})

export { rootReducer, rootPersistConfig }
