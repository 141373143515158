import { lazy, useEffect } from 'react'

// project imports
import DashboardLayout from '../tailwindui/DashboardLayout'
import Loadable from 'components/loading/Loadable'
import AuthGuard from 'guards/AuthGuard'
import OAuthCallback from 'components/OAuthCallback'
import GuestGuard from 'guards/GuestGuard'
import Main from 'layout/main'
const Login = Loadable(lazy(() => import('../views/auth/Login')))
const NotFound = Loadable(lazy(() => import('views/Page404')))

const PromptChainsLanding = Loadable(lazy(() => import('views/usertables/PromptChainsLanding')))
const SharedWorkbook = Loadable(lazy(() => import('views/workbook/SharedWorkbook')))
const Upgrade = Loadable(lazy(() => import('tailwindui/pages/Upgrade')))
const Activity = Loadable(lazy(() => import('views/Activity')))
const Home = Loadable(lazy(() => import('tailwindui/pages/home')))
const TemplateList = Loadable(lazy(() => import('tailwindui/pages/templates/List')))
const Logout = () => {
    useEffect(() => {
        localStorage.clear()
        window.location.href = '/'
    }, [])
    return <div>Logout...</div>
}
export const getRoutes = (isAdmin) => {
    if (isAdmin) {
        const TokenUsage = Loadable(lazy(() => import('views/Users/TokenUsage')))
        const UserAccount = Loadable(lazy(() => import('views/dashboard/UserAccount')))
        const Users = Loadable(lazy(() => import('views/Users')))
        const User = Loadable(lazy(() => import('views/Users/User')))
        const Connections = Loadable(lazy(() => import('views/usertables/Connections')))
        const Blogs = Loadable(lazy(() => import('views/blogs')))
        const BlogDetail = Loadable(lazy(() => import('views/blogs/blogDetail')))
        const UserReport = Loadable(lazy(() => import('views/Users/UserReport')))
        const Toollist = Loadable(lazy(() => import('tailwindui/pages/tools/list')))
        const Tool = Loadable(lazy(() => import('tailwindui/pages/tools/tool')))
        const Categories = Loadable(lazy(() => import('tailwindui/pages/category/list')))
        const Category = Loadable(lazy(() => import('tailwindui/pages/category/category')))
        const TableDetail = Loadable(lazy(() => import('tailwindui/pages/datagrid')))
        const MainRoutes = {
            path: '/dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/dashboard',
                    element: <Home />
                },
                {
                    path: '/dashboard/start-with-ai',
                    element: <PromptChainsLanding />
                },
                {
                    path: '/dashboard/templates',
                    element: <TemplateList />
                },
                {
                    path: '/dashboard/usertable/:id',
                    element: <TableDetail />
                },
                {
                    path: '/dashboard/tables/:id',
                    element: <TableDetail />
                },
                {
                    path: '/dashboard/tables',
                    element: <Home onlyList />
                },
                {
                    path: '/dashboard/upgrade',
                    element: <Upgrade />
                },
                {
                    path: '/dashboard/shared-workbook/:id/:rowId',
                    element: <SharedWorkbook />
                },
                {
                    path: '/dashboard/activity/:type',
                    element: <Activity />
                },
                {
                    path: '/dashboard/users/:id/documents',
                    element: <UserReport entity='documents' />
                },
                {
                    path: '/dashboard/users/:id/runs',
                    element: <UserReport entity='runs' />
                },
                {
                    path: '/dashboard/users/:id/prompt-chains',
                    element: <UserReport entity='promt-chains' />
                },
                {
                    path: '/dashboard/tools/:id',
                    element: <Tool isEdit />
                },
                {
                    path: '/dashboard/tools',
                    element: <Toollist />
                },
                {
                    path: '/dashboard/category/:id',
                    element: <Category isEdit />
                },
                {
                    path: '/dashboard/category',
                    element: <Categories />
                },
                {
                    path: '/dashboard/connections',
                    element: <Connections />
                },
                {
                    path: '/dashboard/usage',
                    element: <TokenUsage />
                },
                {
                    path: '/dashboard/users/:id',
                    element: <User />
                },
                {
                    path: '/dashboard/users',
                    element: <Users />
                },
                {
                    path: '/dashboard/user/account',
                    element: <UserAccount />
                },
                {
                    path: '/dashboard/blogs/new',
                    element: <BlogDetail isNew />
                },
                {
                    path: '/dashboard/blogs/:id',
                    element: <BlogDetail />
                },
                {
                    path: '/dashboard/blogs',
                    element: <Blogs />
                }
            ]
        }
        return MainRoutes
    } else {
        const TokenUsage = Loadable(lazy(() => import('views/bots/TokenUsage')))
        const UserAccount = Loadable(lazy(() => import('views/dashboard/UserAccount')))
        const Connections = Loadable(lazy(() => import('views/usertables/Connections')))
        const TableDetail = Loadable(lazy(() => import('tailwindui/pages/datagrid')))
        const MainRoutes = {
            path: '/dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/dashboard',
                    element: <Home />
                },
                {
                    path: '/dashboard/templates',
                    element: <TemplateList />
                },
                {
                    path: '/dashboard/start-with-ai',
                    element: <PromptChainsLanding />
                },
                {
                    path: '/dashboard/usertable/:id',
                    element: <TableDetail />
                },
                {
                    path: '/dashboard/tables/:id',
                    element: <TableDetail />
                },
                {
                    path: '/dashboard/tables',
                    element: <Home onlyList />
                },
                {
                    path: '/dashboard/upgrade',
                    element: <Upgrade />
                },
                {
                    path: '/dashboard/shared-workbook/:id/:rowId',
                    element: <SharedWorkbook />
                },
                {
                    path: '/dashboard/shared-workbook/:id/:rowId',
                    element: <SharedWorkbook />
                },
                {
                    path: '/dashboard/connections',
                    element: <Connections />
                },
                {
                    path: '/dashboard/usage',
                    element: <TokenUsage />
                },
                {
                    path: '/dashboard/user/account',
                    element: <UserAccount />
                }
            ]
        }
        return MainRoutes
    }
}
export const PublicRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <Main />
        </GuestGuard>
    ),
    children: [
        {
            path: '/auth/logout',
            element: <Logout />
        },
        {
            path: '/',
            element: <Login />
        },
        {
            path: '/redirect',
            element: <OAuthCallback />
        }
    ]
}
export const NotFoundRoutes = {
    path: '*',
    element: <NotFound />
}
